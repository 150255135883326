// app/javascript/controllers/number_field_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  format(event) {
    const allowedKeys = [
      'Backspace',
      'Delete',
      'Tab',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Enter',
    ];

    const isAllowedKey = allowedKeys.includes(event.key);
    const isNotNumeric = event.key < '0' || event.key > '9';

    if (!isAllowedKey && isNotNumeric) event.preventDefault();
  }
}
