import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['dogName', 'submitButton'];

  connect() {
    const nameElement = this.dogNameTarget;
    nameElement.focus();
    this.enableContinue();
  }

  enableContinue() {
    const nameElement = this.dogNameTarget;
    const submitButton = this.submitButtonTarget;

    if (nameElement.value) {
      submitButton.removeAttribute('disabled');
    } else {
      submitButton.setAttribute('disabled', 'disabled');
    }
  }
}
